<template>
  <div>
    <ui-transition>
      <div v-if="schedule.id"></div>
    </ui-transition>
  </div>
</template>

<script>
import ScheduleService from '@/services/ScheduleService'

export default {
  name: 'Preproduction',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: true,
    schedule: {},
  }),
  watch: {
    id() {
      this.fetch()
    },
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.isLoading = true
      this.schedule = await ScheduleService.getOne(this.id, true)
      this.isLoading = false
    },
  },
}
</script>
